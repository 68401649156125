import React, {FunctionComponent, useState, useEffect} from 'react';
import Layout from '../components/layout';
import {
  BlogThumbnail,
  ButtonVariant,
  HeaderCard,
} from '@focusrite-novation/ampify-web-ui/';
import {FooterContent} from '../types/FooterContent';
import {GutterMaxWidth, Gutters} from '../components/Spacing/Gutters';
import {
  AllContentfulCollectiveBlogPost,
  WebPostNodeEdge,
} from '../types/BlogHubContent';
import Theme from '../theme';
import styled from 'styled-components';
import {Button} from '../components/Button';
import SEO from '../components/seo';
import Img from 'gatsby-image';

interface BlogHubProps {
  pageContext: {
    contentfulFooterContent: FooterContent;
    customerPortalBaseUrl: string;
    blogHubContent: AllContentfulCollectiveBlogPost;
  };
  location: Location;
}

const HeaderWrapper = styled.div`
  padding-top: ${Theme.space[6]}px;
  max-width: ${GutterMaxWidth.LARGE};
  margin: auto;
  @media screen and (max-width: ${Theme.breakpoints[2]}px) {
    justify-content: space-evenly;
    padding-top: 0;
  }
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 33.33%;
  column-gap: 75px;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media screen and (max-width: ${Theme.breakpoints[3]}px) {
    justify-content: space-evenly;
    column-gap: normal;
  }
`;

const BlogHub: FunctionComponent<BlogHubProps> = (props) => {
  const {contentfulFooterContent, customerPortalBaseUrl, blogHubContent} =
    props.pageContext;
  const totalArticles = blogHubContent.totalCount;
  const first9Articles = blogHubContent.edges.slice(0, 9);
  const endOfArticles = blogHubContent.edges.slice(9);

  const [postsToDisplay, setPostsToDisplay] =
    useState<WebPostNodeEdge[]>(first9Articles);
  const [showButton, setShowButton] = useState(totalArticles > 9);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleShowMore = async () => {
    setButtonLoading(true);

    await new Promise<void>((resolve) => {
      setTimeout(() => {
        setButtonLoading(false);
        setShowButton(false);
        setPostsToDisplay((currPosts) => [...currPosts, ...endOfArticles]);
        resolve();
      }, 800);
    });
  };

  return (
    <Layout
      {...contentfulFooterContent}
      customerPortalBaseUrl={customerPortalBaseUrl}
    >
      <SEO title="Editorial Hub" />
      <HeaderWrapper>
        <HeaderCard data-testid="blog-header">
          <h2>Latest from The Collective</h2>
        </HeaderCard>
      </HeaderWrapper>
      <Gutters maxWidth={GutterMaxWidth.LARGE} padding={true}>
        <ThumbnailWrapper data-testid="blog-thumbnails-wrapper">
          {postsToDisplay.map((edge, index) => {
            const post = edge.node;
            return (
              <BlogThumbnail
                link={{
                  label: post.metaLinkLabel,
                  href: `/blog/${post.slug}`,
                }}
                key={post.pageTitle + index.toString()}
                image={() => (
                  <Img
                    fluid={post.headerImage.fluid}
                    alt={post.headerImage.title}
                    style={{width: '100%'}}
                  />
                )}
                title={post.pageTitle}
                body={{text: post.metaDescription, maxLength: 150}}
              />
            );
          })}
        </ThumbnailWrapper>
      </Gutters>

      <span data-testid="button-container">
        {showButton && (
          <Button
            data-testid="show-more"
            style={{margin: 'auto'}}
            variant={buttonLoading ? ButtonVariant.LOADING : ButtonVariant.DARK}
            onClick={handleShowMore}
          >
            Show more
          </Button>
        )}
      </span>
    </Layout>
  );
};

export default BlogHub;
